body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Lato-Black;
  src: url("../src/assets/fonts/Lato-Black.ttf");
}
@font-face {
  font-family: Lato-BlackItalic;
  src: url("../src/assets/fonts/Lato-BlackItalic.ttf");
}

@font-face {
  font-family: Lato-Bold;
  src: url("../src/assets/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: Lato-BoldItalic;
  src: url("../src/assets/fonts/Lato-BoldItalic.ttf");
}
@font-face {
  font-family: Lato-Italic;
  src: url("../src/assets/fonts/Lato-Italic.ttf");
}
@font-face {
  font-family: Lato-Light;
  src: url("../src/assets/fonts/Lato-Light.ttf");
}

@font-face {
  font-family: Lato-LightItalic;
  src: url("../src/assets/fonts/Lato-LightItalic.ttf");
}

@font-face {
  font-family: Lato-Regular;
  src: url("../src/assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: Lato-Thin;
  src: url("../src/assets/fonts/Lato-Thin.ttf");
}
@font-face {
  font-family: Lato-ThinItalic;
  src: url("../src/assets/fonts/Lato-ThinItalic.ttf");
}
@font-face {
  font-family: Lato-Medium;
  src: url("../src/assets/fonts/Lato-Medium.ttf");
}
@font-face {
  font-family: Lato-Semibold;
  src: url("../src/assets/fonts/Lato-Semibold.ttf");
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-table tr:not(:last-child) td {
  border-bottom: 1px solid #07b5b533;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #F6F8FB !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {

  background-color: #6592CB !important; 
  border-radius: 11px;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.gradient-outline-btn{
background: linear-gradient(180deg, #07B5B5 0%, #058282 112.5%);
transition: .3s all;
}

.gradient-outline-btn-wrap{
  background-color: white;
}

.gradient-outline-btn-content{
  color: transparent;
  background: linear-gradient(180deg, #07B5B5 0%, #058282 112.5%);
  background-clip: text;
}

.gradient-outline-btn-icon{
  color: black;
  border-color: #07B5B5;
}

.gradient-outline-btn:hover .gradient-outline-btn-wrap{
  background: linear-gradient(180deg, #07B5B5 0%, #058282 112.5%);  
}

.gradient-outline-btn:hover .gradient-outline-btn-content{
  color: white;
}

.gradient-outline-btn:hover .gradient-outline-btn-icon{
  color: white;
  border-color: white;
}
.table_container {
  display: grid;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  /* border-radius: 15px; */
}

.clone_table {
  border-radius: 0;
}